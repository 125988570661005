<template>
  <AuthLayout>
    <div id="sign-in">
      <div v-if="checkTenant" class="description">Hi, Welcome!</div>
      <div v-else class="description">Hi, Welcome back to EZOFIS</div>

      <div class="title">Sign in to your account</div>

      <template v-if="checkTenantLogin">
        <template v-if="!normalLogin">
          <ValidationObserver ref="tenant">
            <Email
              v-model="email"
              :is-loading="isLoading"
              @input="error = ''"
              @enter="validateEmail"
            />

            <BaseButton
              label="continue"
              :is-loading="isLoading"
              class="sign-in-btn"
              @click="validateEmail"
            />
          </ValidationObserver>

          <FormFieldError v-if="error" :error="error" class="text-center" />
        </template>
        <template v-else>
          <ValidationObserver ref="form">
            <Email
              v-model="email"
              :is-loading="isLoading"
              @input="error = ''"
              @enter="validate"
            />

            <Password
              v-model="password"
              :is-loading="isLoading"
              @input="error = ''"
              @enter="validate"
            />

            <BaseButton
              label="sign in"
              :is-loading="isLoading"
              class="sign-in-btn"
              @click="validate"
            />
          </ValidationObserver>

          <FormFieldError v-if="error" :error="error" class="text-center" />
        </template>
      </template>
      <template v-else>
        <ValidationObserver ref="form">
          <template v-if="checkAdlogin">
            <ValidationProvider
              v-slot="{ errors }"
              name="user name"
              :rules="{ required: true }"
            >
              <TextField
                v-model="email"
                is-mandatory
                label="user name"
                class="q-mb-md"
                :is-readonly="isLoading"
                :error="errors[0]"
                @enter="validate"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              name="password"
              :rules="{ required: true }"
            >
              <PasswordField
                v-model="password"
                label="password"
                is-mandatory
                :is-readonly="isLoading"
                :error="errors[0]"
                class="q-mb-md"
                @input="error = ''"
                @enter="validate"
              />
            </ValidationProvider>
          </template>
          <template v-else>
            <Email
              v-model="email"
              :is-loading="isLoading"
              @input="error = ''"
              @enter="validate"
            />
            <Password
              v-model="password"
              :is-loading="isLoading"
              @input="error = ''"
              @enter="validate"
            />
          </template>
        </ValidationObserver>
        <template v-if="!checkAdlogin && checkForgot">
          <div class="row items-center">
            <RememberMe v-model="rememberMe" />

            <q-space />

            <ForgotPassword />
          </div>
        </template>

        <BaseButton
          label="sign in"
          :is-loading="isLoading"
          class="sign-in-btn"
          @click="validate"
        />

        <FormFieldError v-if="error" :error="error" class="text-center" />

        <template v-if="!checkAdlogin">
          <OrDivider v-if="checkTenantSocialLogin" />

          <SocialAuths
            logged-from="WEB"
            :social-login="checkTenantSocialLogin"
            @logged="logged"
            @tenantCheck="tenantCheck"
          />
        </template>
      </template>

      <Modal
        v-model="showTenantListModal"
        width="400px"
        height="400px"
        has-footer
        @input="closeModal"
      >
        <!-- title -->

        <template #title>Tenant List</template>

        <!-- ... -->

        <template #default>
          <div>
            <div class="label">
              Its look like {{ email }} is used with more than one account.
              Which account do you want to use?
            </div>
            <SingleChoiceField
              v-if="false"
              v-model="tenant"
              label=""
              :options-per-line="2"
              :options="tenantList"
              class="q-mb-md"
            />
            <div class="row items-center q-mt-md">
              <template v-for="(user, index) in tenantList">
                <div
                  :key="user.id"
                  class="col-12 tenant"
                  @click="signIn(user.id)"
                >
                  <div class="row items-center q-py-sm">
                    <div class="col title-2">
                      <BaseIcon name="mdi-account" class="q-ml-sm q-mr-sm" />
                      {{ user.label }}
                    </div>
                    <div class="col-auto">
                      <BaseActionButton
                        is-flat
                        no-border
                        icon="mdi-chevron-right"
                        class="q-ml-sm"
                      />
                    </div>
                  </div>
                  <BaseSeparator v-if="index !== tenantList.length - 1" />
                </div>
              </template>
            </div>
            <!-- {{ tenantList }} -->
          </div>
        </template>

        <template #footer>
          <div class="text-secondary cursor-pointer colse" @click="closeModal">
            Sign in with a different email address
          </div>
        </template>
      </Modal>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/layouts/auth/AuthLayout.vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";

import FormFieldError from "@/components/common/form/FormFieldError.vue";
import OrDivider from "@/components/common/OrDivider.vue";

import Email from "./components/Email.vue";
import Password from "./components/Password.vue";
import RememberMe from "./components/RememberMe.vue";
import ForgotPassword from "./components/ForgotPassword.vue";
import SocialAuths from "./components/SocialAuths.vue";

import TextField from "@/components/common/form/text-field/TextField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";

import { auth, profile } from "@/api/factory.js";
import store from "@/store/index.js";
import * as Msal from "msal";

export default {
  name: "SignIn",

  components: {
    AuthLayout,
    ValidationObserver,
    ValidationProvider,
    Email,
    Password,
    RememberMe,
    ForgotPassword,
    FormFieldError,
    OrDivider,
    SocialAuths,
    TextField,
    PasswordField,
    Modal,
    SingleChoiceField,
  },

  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
      isLoading: false,
      error: "",
      normalLogin: false,
      tenantList: [],
      showTenantListModal: false,
      tenant: "",
      socialLogged: false,
      socialEmail: "",
    };
  },

  computed: {
    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com" ||
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return true;
      }
      return false;
    },

    checkTenantLogin() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return true;
      }
      return false;
    },

    checkTenantSocialLogin() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        return false;
      }
      return true;
    },

    checkAdlogin() {
      let origin = location.origin;
      if (origin === "http://172.16.1.118") {
        return true;
      }
      return false;
    },

    checkForgot() {
      let origin = location.origin;
      if (origin === "http://localhost:8080") {
        return true;
      }
      return false;
    },
  },

  // watch: {
  //   tenant() {
  //     if (this.tenant) {
  //       this.signIn(this.tenant);
  //     }
  //   },
  // },

  methods: {
    reset() {
      this.email = "";
      this.password = "";
      this.socialLogged = false;
      this.socialEmail = "";

      // Wait until the models are updated in the UI

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    tenantCheck(email, data) {
      this.socialEmail = email;
      this.socialLogged = true;
      this.tenantList = data.map((tenant) => ({
        id: tenant.id,
        label: tenant.name,
        value: tenant.id,
        email: tenant.email,
      }));
      this.showTenantListModal = true;
    },

    async logged() {
      store.commit("setProfileMenu", []);
      if (this.$store.state.session.profile) {
        let origin = location.origin;
        if (
          origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net"
        ) {
          this.$router.push({ name: "workflows-inbox" });
          return;
        }
        await this.getProfileMenu();
        if (this.$store.state.profileMenus.length) {
          if (
            this.$store.state.profileMenus.find((row) => row.folders)?.Menu[
              "Overview"
            ]
          ) {
            this.$router.push({ name: "repositories-browse" });
            return;
          } else if (
            this.$store.state.profileMenus.find((row) => row.workflows)?.Menu[
              "Overview"
            ]
          ) {
            this.$router.push({ name: "workflows-inbox" });
            return;
          }
        }
      }
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        this.$router.push({ name: "workflows-inbox" });
        return;
      }
      this.$router.push({ name: "repositories-overview" });
    },

    async validate() {
      this.socialLogged = false;
      this.socialEmail = "";
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      this.signIn();
    },

    async validateEmail() {
      const areAllFieldsValid = await this.$refs.tenant.validate();

      if (!areAllFieldsValid) {
        return;
      }

      this.isLoading = true;

      const payload = {
        email: this.email,
      };
      const { error, data } = await auth.emailValidate(1, payload);

      if (error) {
        this.error = error;
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      if (data === "User") {
        this.normalLogin = true;
      } else if (data === "ADUser") {
        this.microsoftSignIn();
      }
    },

    async signIn(tenantId) {
      this.isLoading = true;

      if (this.socialLogged) {
        this.socialLogin(tenantId);
        return;
      }

      const payload = {
        email: this.email,
        password: this.password,
        loggedFrom: "WEB",
      };

      const { error, data, status } = await auth.login(payload, tenantId);

      if (error) {
        this.error = error;
        this.isLoading = false;
        this.showTenantListModal = false;
        return;
      }
      if (status === 300) {
        this.tenantList = data.map((tenant) => ({
          id: tenant.id,
          label: tenant.name,
          value: tenant.id,
          email: tenant.email,
        }));
        this.showTenantListModal = true;
      } else {
        this.logged();
        this.showTenantListModal = false;
      }
    },

    async microsoftSignIn() {
      let clientId = "315d2d09-9633-419e-8e85-18b747eb5be5";
      let origin = location.origin;
      if (origin === "https://edmsuat.sobhaapps.com") {
        clientId = "9db78754-f584-459d-a04d-f5a6d9b1e2cf";
      } else if (origin === "https://edms.sobhaapps.com") {
        clientId = "a403995e-94b6-4805-bee7-2fa606a751bd";
      }

      // Microsoft Authentication
      const mAuth = new Msal.UserAgentApplication({
        auth: {
          clientId: clientId,
          redirectUri: origin,
        },
      });
      await mAuth.loginPopup({
        scopes: ["user.read"],
        loginHint: this.email,
      });
      const user = mAuth.getAccount();
      let email = user.userName;
      // Microsoft Authentication
      this.socialEmail = email;
    },

    async getProfileMenu() {
      const { error, payload } = await profile.getProfileList(
        this.$store.state.session.profile.id
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      let profileMenu = payload ? JSON.parse(payload.menuOptions) : [];
      store.commit("setProfileMenu", profileMenu);
    },

    async socialLogin(tenantId) {
      this.isLoading = true;

      const payload = {
        email: this.socialEmail,
        loggedFrom: "WEB",
      };

      const { error } = await auth.socialLogin(payload, tenantId);

      if (error) {
        this.error = error;
        this.isLoading = false;
        return;
      }

      this.logged();
    },

    closeModal() {
      this.reset();
      this.isLoading = false;
      this.showTenantListModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#sign-in {
  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
  }

  .title {
    @extend .text-lg;
    font-weight: bold;
    color: var(--title-1-color);
    margin-bottom: 12px;
  }

  .sign-in-btn {
    min-height: 44px;
    width: 100%;
    margin-top: 8px;
  }
}

.tenant {
  cursor: pointer;
  // border-bottom: 1px solid #eee;
}
.tenant:hover {
  cursor: pointer;
  background: #eee;
}

.colse {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}
</style>
